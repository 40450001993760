.c_prim120 {
  color: #2340cc;
}

.c_prim100 {
  color: #2748e3;
}

.c_prim8 {
  color: #eef1fd;
}

.c_second100 {
  color: #017fff;
}

.c_AE100 {
  color: #e32f27;
}

.c_AE8 {
  color: #fdeeee;
}

.c_AS100 {
  color: #22c96d;
}

.c_AS8 {
  color: #eefdf4;
}

.c_cta100 {
  color: #e14141;
}

.c_base100 {
  color: #050a1f;
}

.c_base80 {
  color: #4e5674;
}

.c_base60 {
  color: #888d9f;
}

.c_base40 {
  color: #dfe2e7;
}

.c_base20 {
  color: #edeef2;
}

.c_base8 {
  color: #f4f5f9;
}

.c_base0 {
  color: #edeef2;
}

.c_prim-grad {
  color: linear-gradient(90deg, #3bb2f5 0%, #2685dc 100%);
}

:export {
  primary120: #2340cc;
  primary100: #2748e3;
  primary10: #e9f2ff;
  primary8: #eef1fd;
  additionalError100: #e32f27;
  additionalError8: #fdeeee;
  additionalSuccess100: #22c96d;
  additionalSuccess8: #eefdf4;
  base100: #050a1f;
  base80: #4e5674;
  base60: #888d9f;
  base40: #dfe2e7;
  base30: #ecf0f5;
  base20: #edeef2;
  base8: #f4f5f9;
  base0: #ffffff;
}

.title-1 {
  font-size: 46px;
  line-height: 56px;
  font-weight: 300;
}

.title-2 {
  font-size: 39px;
  line-height: 44px;
  font-weight: 300;
}

.title-3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}

.title-4 {
  font-size: 23px;
  line-height: 32px;
  font-weight: 700;
}

.title-5 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.subtitle {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.subtitle-2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.text-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.text-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.caption, .header .nav .nav-list .nav-item_with-counter .counter .counter-text {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.capitalize {
  text-transform: capitalize;
}

.header {
  background-color: #ffffff;
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ecf0f5;
}
.header .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1104px;
  margin: 0 auto;
}
.header .nav .nav-link :global .stroke-icon path {
  stroke: #888d9f;
}
.header .nav :global .active-link .stroke-icon path {
  stroke: #2748e3;
}
@media only screen and (max-width: 375px) {
  .header .nav .logotype-img {
    display: none;
  }
}
.header .nav .lang-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 8px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  margin-right: 16px;
  border: 1px solid #e6e7eb;
  color: var(--cnt-base-80, #4e5674);
  text-align: center;
  font-size: 16px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.header .nav .nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .nav .nav-list .nav-item {
  margin-right: 40px;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .header .nav .nav-list .nav-item {
    margin-right: 16px;
  }
}
.header .nav .nav-list .nav-item_with-counter {
  position: relative;
}
.header .nav .nav-list .nav-item_with-counter .counter {
  position: absolute;
  bottom: -8px;
  right: -12px;
  background-color: #2748e3;
  border-radius: 20px;
  padding: 2px 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .nav .nav-list .nav-item_with-counter .counter .counter-text {
  color: #ffffff;
}
.header .nav .nav-list .nav-item:nth-last-child(1) {
  margin-right: 0;
}
.header .nav .nav-list :global .dropdown {
  display: block;
}