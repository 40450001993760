//colors
$primary120: #2340cc;
$primary100: #2748e3;
$primary10: #e9f2ff;
$primary8: #eef1fd;
$secondary100: #017fff;
$additionalError100: #e32f27;
$additionalError8: #fdeeee;
$additionalSuccess100: #22c96d;
$additionalSuccess8: #eefdf4;
$cta100: #e14141;
$base100: #050a1f;
$base80: #4e5674;
$base60: #888d9f;
$base40: #dfe2e7;
$base30: #ecf0f5;
$base20: #edeef2;
$base8: #f4f5f9;
$base0: #ffffff;
$contentBlack: #060b1f;
$gradPrimary100: linear-gradient(90deg, #3bb2f5 0%, #2685dc 100%);

.c_prim120 {
	color: $primary120;
}

.c_prim100 {
	color: $primary100;
}

.c_prim8 {
	color: $primary8;
}

.c_second100 {
	color: $secondary100;
}

.c_AE100 {
	color: $additionalError100;
}

.c_AE8 {
	color: $additionalError8;
}

.c_AS100 {
	color: $additionalSuccess100;
}

.c_AS8 {
	color: $additionalSuccess8;
}

.c_cta100 {
	color: $cta100;
}

.c_base100 {
	color: $base100;
}

.c_base80 {
	color: $base80;
}

.c_base60 {
	color: $base60;
}

.c_base40 {
	color: $base40;
}

.c_base20 {
	color: $base20;
}

.c_base8 {
	color: $base8;
}

.c_base0 {
	color: $base20;
}

.c_prim-grad {
	color: $gradPrimary100;
}

:export {
	primary120: $primary120;
	primary100: $primary100;
	primary10: $primary10;
	primary8: $primary8;
	additionalError100: $additionalError100;
	additionalError8: $additionalError8;
	additionalSuccess100: $additionalSuccess100;
	additionalSuccess8: $additionalSuccess8;
	base100: $base100;
	base80: $base80;
	base60: $base60;
	base40: $base40;
	base30: $base30;
	base20: $base20;
	base8: $base8;
	base0: $base0;
}
