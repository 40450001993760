@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,700&display=swap&subset=cyrillic-ext");
.c_prim120 {
  color: #2340cc;
}

.c_prim100 {
  color: #2748e3;
}

.c_prim8 {
  color: #eef1fd;
}

.c_second100 {
  color: #017fff;
}

.c_AE100 {
  color: #e32f27;
}

.c_AE8 {
  color: #fdeeee;
}

.c_AS100 {
  color: #22c96d;
}

.c_AS8 {
  color: #eefdf4;
}

.c_cta100 {
  color: #e14141;
}

.c_base100 {
  color: #050a1f;
}

.c_base80 {
  color: #4e5674;
}

.c_base60 {
  color: #888d9f;
}

.c_base40 {
  color: #dfe2e7;
}

.c_base20 {
  color: #edeef2;
}

.c_base8 {
  color: #f4f5f9;
}

.c_base0 {
  color: #edeef2;
}

.c_prim-grad {
  color: linear-gradient(90deg, #3bb2f5 0%, #2685dc 100%);
}

:export {
  primary120: #2340cc;
  primary100: #2748e3;
  primary10: #e9f2ff;
  primary8: #eef1fd;
  additionalError100: #e32f27;
  additionalError8: #fdeeee;
  additionalSuccess100: #22c96d;
  additionalSuccess8: #eefdf4;
  base100: #050a1f;
  base80: #4e5674;
  base60: #888d9f;
  base40: #dfe2e7;
  base30: #ecf0f5;
  base20: #edeef2;
  base8: #f4f5f9;
  base0: #ffffff;
}

/****************************/
/** Reset **/
/****************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: black;
}

.title-1 {
  font-size: 46px;
  line-height: 56px;
  font-weight: 300;
}

.title-2 {
  font-size: 39px;
  line-height: 44px;
  font-weight: 300;
}

.title-3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}

.title-4 {
  font-size: 23px;
  line-height: 32px;
  font-weight: 700;
}

.title-5, .ppp-btn, .pp-btn, .pp-btn_transparent, .pp-btn_error, .pp-btn_base0, .pp-btn_ghost, .pp-btn_light, .pp-btn_fw, .pp-btn_sm, .pp-btn_md {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.subtitle {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.subtitle-2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.text-1, .pp-search-input {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.text-2, .input-range .input-range__label, .pr-btn, .pr-btn_trans, .pr-btn_cta, .pr-btn_prim, .pr-btn_purple-grad, .pr-btn_prim-grad, .pr-btn_with-arrow, .pr-btn_with-arrow__gray, .pr-btn_fw, .pr-btn_wborder:hover, .pr-btn_wborder, .pr-btn_gray, .ppc-btn, .ppc-btn_prim-grad, .ppc-btn_trans_cta, .ppc-btn_trans, .ppc-btn_fw, .pp-btn_sm, .pp-btn_md {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.caption {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.capitalize {
  text-transform: capitalize;
}

.pp-btn, .pp-btn_transparent, .pp-btn_error, .pp-btn_base0, .pp-btn_ghost, .pp-btn_light, .pp-btn_fw, .pp-btn_sm, .pp-btn_md {
  padding: 10px 12px;
  text-align: center;
  color: #ffffff;
  border-radius: 24px;
  background-color: #2748e3;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 200px;
}

.pp-btn_md {
  padding: 8px 16px;
  min-width: 0;
}

.pp-btn_sm {
  padding: 4px 16px;
  min-width: 0;
}

.pp-btn_fw {
  width: 100%;
}

.pp-btn_light {
  color: #2748e3;
  background-color: #eef1fd;
}

.pp-btn_ghost {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
}

.pp-btn_base0 {
  color: #017fff;
  background-color: #ffffff;
}

.pp-btn_error {
  color: #e32f27;
  background-color: #fdeeee;
}

.pp-btn_transparent {
  color: #2748e3;
  background-color: transparent;
}

.pp-btn_multiline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: left;
}

.pp-btn_icon {
  padding-left: 56px;
  background-repeat: no-repeat;
  background-position: 12px 50%;
  border-radius: 32px;
}

.pp-btn:hover, .pp-btn_md:hover, .pp-btn_sm:hover, .pp-btn_fw:hover, .pp-btn_light:hover, .pp-btn_ghost:hover, .pp-btn_base0:hover, .pp-btn_error:hover, .pp-btn_transparent:hover {
  background-color: #2340cc;
}

.pp-btn_light:hover {
  color: #2340cc;
  background-color: #eef1fd;
}

.pp-btn_error:hover {
  color: #e32f27;
  background-color: #fdeeee;
}

.pp-btn:disabled:hover, .pp-btn_md:disabled:hover, .pp-btn_sm:disabled:hover, .pp-btn_fw:disabled:hover, .pp-btn_light:disabled:hover, .pp-btn_ghost:disabled:hover, .pp-btn_base0:disabled:hover, .pp-btn_error:disabled:hover, .pp-btn_transparent:disabled:hover,
.pp-btn_disabled:hover {
  background-color: #edeef2;
  color: #4e5674;
  cursor: not-allowed;
}

.pp-btn_transparent:hover {
  color: #2340cc;
  background-color: transparent;
}

.pp-btn_ghost:hover {
  color: #edeef2;
  border: 1px solid #edeef2;
  background-color: transparent;
}

.pp-btn_base0:hover {
  background-color: #edeef2;
}

.ppp-btn {
  padding: 14px 16px;
  text-align: center;
  color: #ffffff;
  border-radius: 16px;
  background-color: #2748e3;
  border: 2px solid #2748e3;
  outline: none;
  cursor: pointer;
  -webkit-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 200px;
  margin-top: auto;
}

.ppp-btn:hover {
  background-color: #2340cc;
}

.ppp-btn_cta {
  background-color: #e14141;
  border-color: #e14141;
  color: #ffffff;
}

.ppp-btn_cta:hover {
  background-color: #e14141;
}

.ppp-btn_transparent {
  color: #4e5674;
  border-color: #4e5674;
  background-color: transparent;
}

.ppp-btn_transparent:hover {
  color: #050a1f;
  border-color: #050a1f;
  background-color: transparent;
}

.ppp-btn_sm {
  min-width: auto;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 0;
  padding: 12px 16px;
}

.ppp-btn:disabled {
  background-color: #888d9f;
  color: #ffffff;
}

.ppc-btn, .ppc-btn_prim-grad, .ppc-btn_trans_cta, .ppc-btn_trans, .ppc-btn_fw {
  padding: 8px 16px;
  text-align: center;
  color: #ffffff;
  border-radius: 20px;
  background-color: #017fff;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.ppc-btn_fw {
  width: 100%;
}

.ppc-btn_trans {
  color: #017fff;
  background-color: transparent;
}

.ppc-btn_trans_cta {
  color: #e14141;
  background-color: transparent;
}

.ppc-btn_trans:hover {
  color: #2748e3;
  background-color: transparent;
}

.ppc-btn_prim-grad {
  background: -webkit-gradient(linear, left top, right top, from(#3bb2f5), to(#2685dc));
  background: linear-gradient(90deg, #3bb2f5 0%, #2685dc 100%);
}

.pr-btn, .pr-btn_trans, .pr-btn_cta, .pr-btn_prim, .pr-btn_purple-grad, .pr-btn_prim-grad, .pr-btn_with-arrow, .pr-btn_with-arrow__gray, .pr-btn_fw, .pr-btn_wborder:hover, .pr-btn_wborder, .pr-btn_gray {
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 8px 16px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: #060b1f;
  background-color: #ffffff;
  outline: none;
}

.pr-btn_gray {
  background-color: #f4f5f9;
  color: #4e5674;
}

.pr-btn_wborder {
  border: 1px solid #dfe2e7;
}

.pr-btn_wborder:hover {
  border: 1px solid #888d9f;
}

.pr-btn_fw {
  width: 100%;
}

.pr-btn_with-arrow, .pr-btn_with-arrow__gray {
  padding-right: 32px;
  background-image: url("../imgs/arrows/Arrow-down.svg");
  background-size: 16px;
  background-position: calc(100% - 8px) 50%;
  background-repeat: no-repeat;
}

.pr-btn_with-arrow__gray {
  color: #888d9f;
  background-color: #f4f5f9;
}

.pr-btn_prim, .pr-btn_purple-grad, .pr-btn_prim-grad {
  background-color: #2748e3;
  color: #ffffff;
}

.pr-btn_prim-grad {
  background: -webkit-gradient(linear, left top, right top, from(#3773fd), to(#7236fb));
  background: linear-gradient(90deg, #3773fd 0%, #7236fb 100%);
}

.pr-btn_purple-grad {
  background: -webkit-gradient(linear, right top, left top, from(#dd128c), to(#c116dd));
  background: linear-gradient(270deg, #dd128c 0%, #c116dd 100%);
}

.pr-btn_prim:hover, .pr-btn_prim-grad:hover, .pr-btn_purple-grad:hover {
  background-color: #2340cc;
}

.pr-btn_cta {
  background-color: #e14141;
  color: #ffffff;
}

.pr-btn_trans {
  background-color: transparent;
  color: #4e5674;
}

.icon-btn, .round-checkbox, .round-checkbox_selected, .burger-icon-btn_gray, .edit-icon-btn_gray, .delete-icon-btn_gray, .edit-icon-btn_white, .delete-icon-btn_white, .delete-icon-btn, .play-btn, .pause-btn, .volume-on-btn, .volume-off-btn, .link-to-btn, .arrow-down-btn, .more-btn, .back-btn, .forward-btn_dark, .back-btn_dark, .forward-btn_2, .forward-btn, .back-btn_2 {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: #f4f5f9;
  border-radius: 100%;
  cursor: pointer;
  border: none;
}

.back-btn, .forward-btn_dark, .back-btn_dark, .forward-btn_2, .forward-btn, .back-btn_2 {
  background-color: #ffffff;
  border: 1px solid #ecf0f5;
  -webkit-transition: border-color 0.3s ease-in, background-position 0.2s ease-out;
  transition: border-color 0.3s ease-in, background-position 0.2s ease-out;
  background-image: url("../imgs/Back.svg");
  outline: none;
}

.forward-btn, .back-btn_2 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.forward-btn_2 {
  background-image: url("../imgs/arrows/Arrow-right_gray.svg");
}

.back-btn_2 {
  border-color: #ecf0f5;
  background-image: url("../imgs/arrows/Arrow-right_gray.svg");
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.forward-btn_dark, .back-btn_dark {
  border-color: rgba(6, 11, 31, 0.4);
  background-color: rgba(6, 11, 31, 0.4);
  background-image: url("../imgs/arrows/Arrow-right_white.svg");
}

.back-btn_dark {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.back-btn:hover, .forward-btn:hover, .forward-btn_2:hover, .back-btn_2:hover, .forward-btn_dark:hover, .back-btn_dark:hover {
  border-color: #888d9f;
  background-position: 40% 50%;
}

.forward-btn_2:hover {
  border-color: #ecf0f5;
  background-position: center;
}

.forward-btn_dark:hover, .back-btn_dark:hover {
  border-color: rgba(6, 11, 31, 0.4);
  background-position: center;
}

.more-btn {
  border-radius: 8px;
  background-image: url("../imgs/More.svg");
}

.arrow-down-btn {
  background-image: url("../imgs/ArrowLeft.svg");
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  outline: none;
}

.link-to-btn {
  background-image: url("../imgs/ArrowLeft.svg");
}

.volume-on-btn, .volume-off-btn {
  background-image: url("../imgs/profile/VolumeOn.svg");
  background-color: rgba(6, 11, 31, 0.4);
  outline: none;
}

.volume-off-btn {
  background-image: url("../imgs/profile/VolumeOff.svg");
}

.play-btn, .pause-btn {
  width: 56px;
  height: 56px;
  background-image: url("../imgs/profile/Play.svg");
  background-color: rgba(6, 11, 31, 0.4);
  outline: none;
}

.pause-btn {
  background-image: url("../imgs/profile/Pause.svg");
}

.pp-btn:disabled, .pp-btn_md:disabled, .pp-btn_sm:disabled, .pp-btn_fw:disabled, .pp-btn_light:disabled, .pp-btn_ghost:disabled, .pp-btn_base0:disabled, .pp-btn_error:disabled, .pp-btn_transparent:disabled,
.pp-btn_disabled,
.ppp-btn:disabled,
.ppp-btn_disabled,
.pr-btn:disabled,
.pr-btn_gray:disabled,
.pr-btn_wborder:disabled,
.pr-btn_fw:disabled,
.pr-btn_with-arrow:disabled,
.pr-btn_with-arrow__gray:disabled,
.pr-btn_prim:disabled,
.pr-btn_prim-grad:disabled,
.pr-btn_purple-grad:disabled,
.pr-btn_cta:disabled,
.pr-btn_trans:disabled {
  background-color: #edeef2;
  color: #888d9f;
  cursor: not-allowed;
}

.ppp-btn:disabled,
.ppp-btn_disabled {
  border-color: #edeef2;
}

.delete-icon-btn {
  background-image: url("../imgs/Delete.svg");
}

.delete-icon-btn_white {
  border-color: rgba(6, 11, 31, 0.4);
  background-color: rgba(6, 11, 31, 0.4);
  background-image: url("../imgs/feed/Delete_white.svg");
}

.edit-icon-btn_white {
  border-color: rgba(6, 11, 31, 0.4);
  background-color: rgba(6, 11, 31, 0.4);
  background-image: url("../imgs/feed/Edit.svg");
}

.delete-icon-btn_gray {
  background-color: transparent;
  border-radius: 0;
  background-image: url("../imgs/settings/Delete.svg");
}

.edit-icon-btn_gray {
  background-color: transparent;
  border-radius: 0;
  background-image: url("../imgs/settings/Edit.svg");
}

.burger-icon-btn_gray {
  background-color: transparent;
  border-radius: 0;
  background-image: url("../imgs/settings/Burger.svg");
}

.label-btn, .label-btn_gray {
  color: #2748e3;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  -webkit-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
}

.label-btn_gray {
  color: #888d9f;
}

.label-btn:hover, .label-btn_gray:hover {
  color: #2340cc;
}

.label-btn_gray:hover {
  color: #4e5674;
}

.label-btn:disabled, .label-btn_gray:disabled {
  color: #888d9f;
  cursor: not-allowed;
}

.btn_trans {
  border: none;
  background-color: transparent;
  outline: 0;
  padding: 0;
  cursor: pointer;
}

body {
  background-color: #f4f5f9;
}

[hidden] {
  display: none !important;
}

a.link {
  color: #2748e3;
}

a.link:hover {
  color: #2340cc;
  text-decoration: underline;
}

a.link_inherit,
a.link_inherit:hover {
  color: inherit;
}

.center {
  text-align: center;
}

.blank {
  background-color: #ffffff;
  padding: 16px 20px 48px;
  margin-top: 20px;
  border-radius: 16px;
  max-width: 940px;
  border: 1px solid #ecf0f5;
}
@media only screen and (min-width: 500px) {
  .blank {
    margin: 20px auto;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex_centred {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex_centred__h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex_centred__w {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.mr_24 {
  margin-right: 24px;
}

.ml_24 {
  margin-left: 24px;
}

.mb_24 {
  margin-bottom: 24px;
}

.mt_24 {
  margin-top: 24px;
}

.mr_16 {
  margin-right: 16px;
}

.ml_16 {
  margin-left: 16px;
}

.mb_16 {
  margin-bottom: 16px;
}

.mt_16 {
  margin-top: 16px;
}

.ml_8 {
  margin-left: 8px;
}

.mr_8 {
  margin-right: 8px;
}

.mb_8 {
  margin-bottom: 8px;
}

.mt_8 {
  margin-top: 8px;
}

.fw {
  width: 100%;
}

.opc_6 {
  opacity: 0.6;
}

.pointer {
  cursor: pointer;
}

.pagination-autoload-flag {
  width: 100%;
  height: 60vh;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 16px;
  left: 0;
  opacity: 0;
  z-index: -100;
}

.pp-input {
  border: none;
  outline: none;
}

.icon_lg, .icon_lg__round {
  width: 56px;
  height: 56px;
  max-width: 100%;
}

.icon_md, .icon_md__round {
  width: 40px;
  height: 40px;
  max-width: 100%;
}

.icon_sm, .icon_sm__round {
  width: 32px;
  height: 32px;
  max-width: 100%;
}

.icon_xs, .round-checkbox, .round-checkbox_selected, .icon_xs__round {
  width: 24px;
  height: 24px;
  max-width: 100%;
}

.icon_xxs, .icon_xxs__round {
  width: 16px;
  height: 16px;
  max-width: 100%;
}

.icon_lg__round {
  border-radius: 100%;
}

.icon_md__round {
  border-radius: 100%;
}

.icon_sm__round {
  border-radius: 100%;
}

.icon_xs__round {
  border-radius: 100%;
}

.icon_xxs__round {
  border-radius: 100%;
}

.set-password {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.set-password .set-password-container {
  background-color: white;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 60px 30px 30px 30px;
  max-width: 400px;
  width: 100%;
  margin: 0 30px;
}
.set-password .set-password-container .set-password-header {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
.set-password .set-password-container .set-password-submit-btn {
  margin-top: 10px;
}
.set-password .set-password-container-success {
  background-color: #3ab97e;
  max-width: 300px;
  padding: 65px 0 60px 0;
  text-align: center;
  color: white;
}

.user-profile-redirect .blank {
  max-width: 700px;
  margin: 20px auto;
}
@media only screen and (max-width: 600px) {
  .user-profile-redirect .blank {
    border: none;
    border: 1px solid #edeef2;
    border-radius: 0;
    padding: 28px 10px;
    margin: 20px 0 52px;
  }
}
.user-profile-redirect .links-container {
  margin-top: 40px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
@media only screen and (max-width: 650px) {
  .user-profile-redirect .links-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.user-profile-redirect .links-container .link {
  width: 100%;
}
.user-profile-redirect .links-container .link-img {
  max-width: 280px;
  width: 100%;
  margin-bottom: 8px;
}

.round-checkbox, .round-checkbox_selected {
  background-image: url("../imgs/Checkbox-unpicked.svg");
  background-size: cover;
  background-color: transparent;
}

.round-checkbox_selected {
  background-image: url("../imgs/Checkbox-picked.svg");
}

.wrap-text,
.break-word {
  word-break: break-word;
  word-wrap: break-word;
}

.pre-wrap {
  white-space: pre-wrap;
}

.no-wrap {
  white-space: nowrap;
}

.break-word-container, .break-word-container_tc {
  display: table;
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
}

.break-word-container_tc {
  display: table-cell;
}

.pp-list .item {
  border-bottom: 1px solid #ecf0f5;
  padding: 28px 16px;
}
.pp-list .item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pp-list .item-row .item-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pp-list .item-row .item-content .item-img {
  margin-right: 16px;
}
.pp-list .item-row .social-connect-input {
  margin-top: 28px;
  max-width: 340px;
}

.input-range {
  height: 48px !important;
  position: relative;
  background-color: #edeef2;
  border-radius: 8px;
  border-left: 8px solid #2748e3;
}
.input-range .input-range__label {
  position: absolute;
  top: 50%;
  margin-top: -12px;
}
.input-range .input-range__label--min {
  left: 8px;
  color: #ffffff;
  z-index: 1;
}
.input-range .input-range__label--max {
  right: 14px;
  color: #888d9f;
}
.input-range .input-range__track--background {
  height: 48px;
  background-color: #edeef2;
  border-radius: 8px;
  top: 0;
  cursor: pointer;
  border-right: 56px solid #edeef2;
  position: relative;
}
.input-range .input-range__track--active {
  background-color: #2748e3;
  height: 48px;
  -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
  transition: left 0.3s ease-out, width 0.3s ease-out;
  position: relative;
  margin-left: -1px;
}
.input-range .input-range__slider-container {
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
  top: 0;
  margin-left: -2px;
}
.input-range .input-range__slider-container .input-range__label--value {
  margin: 0 0 0 0;
  height: 48px;
  width: 48px;
  text-align: center;
  border-left: 24px solid #2748e3;
  border-right: 24px solid #edeef2;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 3;
}
.input-range .input-range__slider-container .input-range__label--value .input-range__label-container {
  height: 48px;
  width: 48px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 100%;
  display: block;
  padding-top: 12px;
  margin-left: -24px;
}
.input-range .input-range__slider-container .input-range__slider {
  height: 48px;
  width: 48px;
  background-color: transparent;
  z-index: 4;
  position: absolute;
}

.dropdown .dropdown-list {
  margin: 16px 0;
}
.dropdown .dropdown-list .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 20px !important;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.dropdown .dropdown-list .dropdown-item img,
.dropdown .dropdown-list .dropdown-item .item-img {
  margin-right: 16px;
}
.dropdown .dropdown-list .dropdown-item svg {
  fill: #888d9f;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.dropdown .dropdown-list .dropdown-item .wallet-icon {
  fill: none;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.dropdown .dropdown-list .dropdown-item .wallet-icon .wallet-icon-rect {
  fill: #888d9f;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.dropdown .dropdown-list .dropdown-item .wallet-icon .wallet-icon-rect-base {
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  stroke: #888d9f;
  fill: none;
}
.dropdown .dropdown-list .dropdown-item:hover {
  background: #f4f5f9;
}
.dropdown .dropdown-list .dropdown-text {
  padding: 12px 20px;
}
.dropdown .dropdown-list .dropdown-subtitle {
  padding: 16px 20px 8px;
}

.pp-search-input {
  background-color: #f4f5f9;
  color: #888d9f;
  border-radius: 24px;
  outline: none;
  border: none;
  padding: 9px 16px 7px 40px;
  width: 246px;
  background-image: url("../imgs/search.svg");
  background-repeat: no-repeat;
  background-position: 12px 50%;
  background-size: 18px 18px;
  margin: 12px 0 8px 8px;
}

.passwordInput input {
  text-security: disc;
  -webkit-text-security: disc;
}

.inputs-container {
  max-width: 340px;
  width: 100%;
}

.cir-prog_white svg path {
  stroke: #ffffff;
}

.ws_pl {
  white-space: pre-line;
}

.scrollable-modal-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(80vh - 96px);
}

.circular-progress-container {
  min-height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.circular-progress-container_inline {
  padding: 16px;
  width: 100%;
  text-align: center;
}

.no-padding {
  padding: 0;
}

.styled-list {
  white-space: normal;
}
.styled-list .list-item__checked-icon {
  background: url("../imgs/checked-bullet.svg") no-repeat left center;
  padding: 3px 0px 3px 30px;
  list-style: none;
}